import { useTranslation } from "react-i18next";
import ContainerOrg from "../ContainerOrg";
import GridOrg from "../GridOrg";
import PushOrg from "../PushOrg";
import SectionOrg from "../SectionOrg";
import TextAtm from "../TextAtm";
import ExternalLinkAtm from "../ExternalLinkAtm";
import FlexOrg from "../FlexOrg";
import WidthMol from "../WidthMol";

function Footer() {
  const { t, i18n } = useTranslation();

  return (
    <SectionOrg
      spacingTop={{ small: 40, medium: 80, large: 80 }}
      spacingBottom={{ small: 40, medium: 80, large: 80 }}
      backgroundColor="primary"
      htmlTag="footer"
    >
      <ContainerOrg width="medium">
        <PushOrg
          spacingBottom={40}
          spacingLeft={{ small: 20, medium: 0, large: 0 }}
          spacingRight={{ small: 20, medium: 0, large: 0 }}
        >
          <TextAtm color="third" typography="largeBold" textAlign="center">
            {t("offer.header")}!
          </TextAtm>
        </PushOrg>

        <GridOrg
          columns={{ small: 1, medium: 3, large: 3 }}
          gap={{ small: 0, medium: 16, large: 40 }}
        >
          <FlexOrg alignItems="center">
            <FlexOrg alignItems="flex-start">
              <TextAtm color="third" typography="small">
                {t("contact.text.first")}
              </TextAtm>

              <PushOrg spacingBottom={32} spacingTop={32}>
                <TextAtm color="third" typography="small">
                  <ExternalLinkAtm href="tel:+48601856685">
                    {t("contact.text.phone")}.: + 48 601 856 685
                  </ExternalLinkAtm>
                </TextAtm>

                <TextAtm color="third" typography="small">
                  <ExternalLinkAtm href="mailto:info@morsztyn.com">
                    e-mail: info@morsztyn.com
                  </ExternalLinkAtm>
                </TextAtm>
              </PushOrg>

              <TextAtm color="third" typography="small">
                Jaszkowo 16, 63-112 Brodnica
              </TextAtm>
            </FlexOrg>
          </FlexOrg>

          <PushOrg
            spacingBottom={40}
            spacingTop={{ small: 40, medium: 0, large: 0 }}
          >
            <FlexOrg alignItems="center">
              <img
                width={200}
                height={150}
                src={
                  i18n.language === "pl"
                    ? "/images/icons/logo-white.svg"
                    : "/images/icons/logo-white-en.svg"
                }
                alt="logo"
              />
            </FlexOrg>
          </PushOrg>

          <FlexOrg alignItems="center">
            <FlexOrg alignItems="flex-start">
              <TextAtm color="third" typography="small">
                © 2023 Małgorzata Morsztyn.
              </TextAtm>

              <TextAtm color="third" typography="small">
                {t("contact.text.second")}
              </TextAtm>

              <WidthMol width={{ small: 200, medium: 300, large: 300 }}>
                <TextAtm color="third" typography="small">
                  {t("contact.text.third")}
                </TextAtm>
              </WidthMol>
            </FlexOrg>
          </FlexOrg>
        </GridOrg>

        <PushOrg spacingTop={20}>
          <TextAtm color="third" typography="small" textAlign="center">
            <ExternalLinkAtm to="/privacy">
              {t("privacy.title")}
            </ExternalLinkAtm>
          </TextAtm>
        </PushOrg>
      </ContainerOrg>
    </SectionOrg>
  );
}

export default Footer;
